<template>
  <b-form-radio-group
    v-if="isUsable"
    v-model="selectedRule"
    :buttons="buttons"
    :button-variant="buttonVariant"
    :plain="plain"
    :lazy="lazy">
<!--    @change="changed"
    >-->
    <b-form-radio v-for="rule in rules" :key="rule.code" :value="rule.code">{{ rule.RULE.name }}</b-form-radio>
  </b-form-radio-group>
</template>

<script>
import { SEASON_RULE } from "@/LocalDB";
import RoundsConfigurationHelper from "@/services/helpers/roundsConfigurationHelper";

export default {
  props: {
    //competition: { type: Object, required: true},
    round: { type: Object, required: true},
    level: { type: Object, required: true},
    category: {type: Object, default: null},
    buttons: { type: Boolean, default: false},
    buttonVariant: { style: String, default:null},
    plain: { type:Boolean, default:false},
    lazy: { type:Boolean, default:false},
  },

  computed: {
    isUsable(){
      return true;
    },
    rules(){
      var ret = SEASON_RULE.query()
                  .where('competition_type', this.round.COMPETITION.competition_type)
                  .where('year', this.round.COMPETITION.year)
                  .where('level', this.level.level)
                  .get()
      return ret;
    },

    selectedRule:{
      get(){
        return (this.category == null)
                ? this.level.rule
                : this.category.rule;
      },
      set(newValue){
        this.$showRefresher()
          .then(() => {
            if(this.category == null){
                RoundsConfigurationHelper.changeRuleForLevel(this.level.COMPETITION.id, this.level.round_number, this.level.code, newValue)
             }  else {
                RoundsConfigurationHelper.changeRuleForLevelAndCategory(this.level.COMPETITION.id, this.level.round_number, this.level.code, this.category.code, newValue)
            }
 /*              this.level.rule = newValue;
              RST_LEVEL.insertOrUpdate({ data : this.level });
            } else {
              this.category.rule = newValue;
              RST_CATEGORY.insertOrUpdate({ data: this.category });
            }*/
            this.$hideRefresher();
        });
      }
    }
  },/*
  methods: {
    changed(newValue)
    {
      if(this.category == null || this.category == '')
      {
        this.levelEdited.rule = newValue;
        RST_LEVEL.insertOrUpdate({ data : this.levelEdited });
      } else {
        this.categoryEdited.ru = newValue;
        RST_CATEGORY.insertOrUpdate({ data : this.categoryEdited});
      }

    }
  }*/
}
</script>
